<script setup lang="ts">
</script>

<template>
  <div class="custom-header">
    <div id="init_header" />
  </div>
  <div class="center-container">
    <slot />
  </div>
  <defaultFooter />
</template>

<style lang="scss" scoped>
.center-container {
  background-color: white;
  min-height: 100vh;
}

.custom-header {
  position: relative;
  min-height: 81px;
  background: var(--Brand-Green);
  min-width: 1190px;
}
</style>
